<script setup lang="ts">
import { reactive, onBeforeMount, computed } from 'vue'
import { useOrganizationStore } from '../../stores/organizationStore'
import type { OrganizationResponse, Palette } from '../../types/organization'
import type { InputsData } from '../../types/inputsData'
import { useWindowSize } from '../../useWindowSize';
import router from '../../router';
import logo from '../../assets/officetime/logo.svg'

const { width } = useWindowSize()
const isMobile = computed(() => width < 431)

const organizationStore = useOrganizationStore()

const state = reactive<{
    organization: OrganizationResponse | null,
    orgCode: string,
    colors: Palette,
    rateInfo: InputsData,
    tags: string[],
    textareaValue: string,
    inputInfo: InputsData | null,
    validData: boolean,
    feedback: string,
    loading: boolean
}>({
    organization: null,
    orgCode: '',
    colors: {
        mainColor: '#FBBC00',
        additionalColor: '#FBBC001F'
    },
    rateInfo: {
        value: 0 || '',
        isValid: true
    },
    textareaValue: '',
    tags: [],
    inputInfo: null,
    validData: true,
    feedback: '',
    loading: false
})

onBeforeMount(async () => {

})

async function sendData() {
    const valid = await checkData()

    if (valid) {
        state.loading = true
    }
}

async function checkData() {
    if (state.inputInfo?.value) {
        if (!state.inputInfo?.isValid) {
            state.inputInfo = { ...state.inputInfo, }
            state.validData = false
        } else {
            state.validData = true
        }
    }
    if (state.validData) {
        return true
    }
}
</script>

<template>
    <div class="wrapper">
        <div class="form-content">
            <div class="organization-section">
                <div class="logo">
                    <img :src="logo" alt="Organization Logo">
                </div>
            </div>
            <form class="feedback-form" @submit.prevent.stop="sendData">
                <div class="form-wrapper">
                    <div class="top-content">
                        <h2>Оставьте комментарий</h2>
                    </div>
                    <div class="inputs-content">
                        <u-input :type="'email'" :variant="'officetime'" :colors="state.colors"
                            v-model="state.inputInfo" />
                        <u-textarea :variant="'officetime'" :colors="state.colors" :placeholder="'Введите сообщение'"
                            v-model="state.textareaValue" />
                    </div>
                </div>
                <u-button :type="['primary']" :variant="'officetime'" :loading="state.loading" />
            </form>
        </div>
    </div>
</template>

<style scoped>
.wrapper {
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    max-width: 100%;
    min-height: 100vh;
    padding: 1.6em 0;
    background: linear-gradient(180deg, #2D4B6B 0%, #042447 100%) !important;
    font-family: var(--officetime-font), sans-serif !important;
}

.form-content {
    width: 31.25em;
    display: flex;
    flex-flow: column nowrap;
    justify-content: center;
    align-items: center;
    gap: clamp(1.25em, 1.25em, 5.333vw);
}

.organization-section {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    padding: 0 0.813em;
}

.inputs-content {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: clamp(0.875em, 0.875em, 3.733vw);
}

.logo {
    width: clamp(13.5em, 13.5em, 9vw);
}

.logo img {
    width: 100%;
    height: auto;
}

.org-info {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
}

.org-info h4 {
    font-size: var(--medium);
    font-size: clamp(0.75em, 0.75em, 2.667vw);
    color: var(--grey-additional);
}

.org-info h2 {
    font-weight: var(--bold);
    font-size: clamp(1em, 1em, 4.267vw);
    color: white;
}

.feedback-form {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    width: 100%;
    background-color: #2D4B6B;
    min-height: fit-content;
    border-radius: 2em;
    padding: 1.563em 1.563em 1.688em 1.563em;
    gap: 1.688em;
}

.form-wrapper {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1.688em;
}

.top-content {
    display: flex;
    flex-flow: column nowrap;
    align-items: center;
    gap: 1.5em;
}

.top-content h2 {
    font-size: clamp(1.688em, 1.688em, 5.333vw);
    font-weight: var(--extrabold);
    color: white;
}

.info {
    width: 100%;
    display: flex;
    flex-flow: row nowrap;
    align-items: flex-start;
    gap: clamp(0.313em, 0.313em, 1.333vw);
    padding: 0 clamp(0.313em, 0.313em, 1.6vw);
}

.info img {
    width: clamp(0.875em, 0.875em, 3.733vw);
    height: auto;
}

.info p {
    font-size: clamp(0.75em, 0.75em, 2.667vw);
    font-weight: var(--regular);
}

/* Animation */

.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 2s cubic-bezier(1, 0.5, 0.8, 1);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateX(-20px);
    opacity: 0;
}

@media screen and (max-width: 431px) {
    .wrapper {
        display: flex;
        flex-flow: column nowrap;
        justify-content: flex-start;
        align-items: center;
        max-width: 100%;
        height: clamp(100%, 100%, 100lvh);
        padding: 0 0;
    }

    .form-content {
        width: 100vw;
        height: 100lvh;
        gap: clamp(1.25em, 1.25em, 5.333vw);
    }

    .organization-section {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: center;
        justify-content: space-between;
        padding: 5.333vw 4vw 4vw 4vw;
    }

    .inputs-content {
        width: 100%;
        display: flex;
        flex-flow: column nowrap;
        align-items: center;
        gap: clamp(0.875em, 0.875em, 3.733vw);
    }

    .org-info {
        width: 80%;
    }

    .org-info h4 {
        font-size: var(--medium);
        font-size: clamp(0.75em, 0.75em, 2.667vw);
        color: var(--grey-additional);
    }

    .org-info h2 {
        font-weight: var(--bold);
        font-size: clamp(1em, 1em, 4.267vw);
    }

    .feedback-form {
        justify-content: space-between;
        width: 100%;
        min-height: auto;
        height: 100%;
        padding: 6.667vw 4vw 4vw 4vw;
    }

    .lovely-image {
        display: flex;
        align-items: center;
        justify-content: center;
        width: clamp(5.375em, 5.375em, 20vw);
        height: clamp(5.375em, 5.375em, 20vw);
        padding: 1em 0.563em 0.563em 0.688em;
        border-radius: 50%;
    }

    .lovely-image img {
        width: clamp(3.125em, 3.125em, 13.333vw);
        height: clamp(3.125em, 3.125em, 13.333vw);
    }

    .top-content {
        gap: 6.667vw;
    }

    .info {
        width: 100%;
        display: flex;
        flex-flow: row nowrap;
        align-items: flex-start;
        gap: clamp(0.313em, 0.313em, 1.333vw);
        padding: 0 clamp(0.313em, 0.313em, 1.6vw);
    }

    .info img {
        width: clamp(0.875em, 0.875em, 3.733vw);
        height: auto;
    }

    .info p {
        font-size: clamp(0.75em, 0.75em, 2.667vw);
        font-weight: var(--regular);
    }
}
</style>
