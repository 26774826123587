import { defineStore } from 'pinia'
import axios from 'axios'
import type { OrganizationResponse } from '../types/organization'

axios.defaults.withCredentials = true
axios.defaults.timeout = 20000
axios.defaults.baseURL = import.meta.env.VITE_APP_BASE_URL

const username = import.meta.env.VITE_APP_API_LOGIN
const password = import.meta.env.VITE_APP_API_PASS
const basicAuth = `Basic ${btoa(username + ':' + password)}`

interface Organization {
  organization: OrganizationResponse | null
  error: string | null
  loading: boolean
}

export const useOrganizationStore = defineStore('feedback', {
  state: (): Organization => ({
    organization: null,
    error: null,
    loading: false
  }),
  actions: {
    async getOrganizationData(orgCode: number): Promise<void> {
      this.loading = true
      this.error = null

      try {
        const response = await axios.get<OrganizationResponse>(`account/${orgCode}`, {
          headers: {
            'Authorization': basicAuth,
            'Content-Type': 'application/json'
          }
        })
        console.log(basicAuth)
        this.organization = response.data
      } catch (error: any) {
        this.error = error.message
        console.log(error)
      } finally {
        this.loading = false
      }
    },
    async sendFeedback(orgCode: string, feedback: string): Promise<number | void> {
      this.loading = true
      this.error = null

      try {
        const response = await axios.post(
          `sendFeedback`,
          {
            codeOrg: orgCode,
            feedback: feedback
          },
          {
            headers: {
              'Authorization': basicAuth,
              'Content-Type': 'application/json'
            }
          }
        )
        return response.status
      } catch (error: any) {
        console.log(error)
        return error
      }
    }
  },
  getters: {
    getOrganization: (state: Organization) => {
      return state.organization ? state.organization : null
    },
    isLoading: (state: Organization) => state.loading
  }
})
