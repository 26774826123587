<script setup lang="ts">
import { watch, ref } from 'vue'
import { withDefaults, reactive, onBeforeMount } from 'vue'

const props = withDefaults(defineProps<{
    type: string[],
    color: string,
    loading: boolean
    variant: string
}>(), {
    type: () => ['primary', 'remove'],
    loading: false
})

const theme = reactive({
    mainColor: ''
})

onBeforeMount(() => {
    if (props.variant === 'officetime') {
        const rootStyles = getComputedStyle(document.documentElement);

        theme.mainColor = rootStyles.getPropertyValue('--officetime-main-color');
    }
})

const emit = defineEmits<{
    (event: 'removeValue', value: boolean): void
}>()                    

const loadingRef = ref<boolean>(false)

const removeValue = function () {
    emit('removeValue', true)
}

watch(() => props.loading, (newValue) => {
    loadingRef.value = newValue
})
</script>

<template>
    <button v-if="props.type.includes('remove')" @click.prevent="removeValue" class="remove-btn"><img
            src="../assets/icons/remove.svg" alt=""></button>
    <button v-if="props.type.includes('primary')" :class="[`${props.variant}`, 'primary-button']"
        :style="{ backgroundColor: props.color || theme.mainColor }" :disabled="props.loading">
        <div v-if="props.loading" class="loader"></div>
        <span v-else>Отправить</span>
    </button>
</template>

<style scoped>
.button-content {
    width: 100%;
}

.remove-btn {
    all: unset;
    cursor: pointer;
}

.remove-btn img {
    position: absolute;
    z-index: 3000;
    top: 0;
    right: 0;
    margin: clamp(1.125em, 1.125em, 5.333vw);
    background: transparent;
    border: none;
    cursor: pointer;
}

.primary-button {
    position: relative;
    width: 100%;
    height: clamp(3.75em, 3.75em, 16vw);
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    justify-content: center;
    border-radius: clamp(1.563em, 1.563em, 0.417vw);
    cursor: pointer;
    border: none;
}

.primary-button span {
    font-size: clamp(1em, 1em, 4.8vw);
    font-weight: var(--bold);
    color: white;
}

.officetime.primary-button span {
    font-family: var(--officetime-font)!important;
    font-weight: var(--bold);
    color: white;
}

.primary-button:disabled {
    cursor: not-allowed;
    opacity: 0.7;
}

.loader {
    width: 1em;
    height: 1em;
    border: 0.2em solid white;
    border-top-color: transparent;
    border-radius: 50%;
    animation: spin 1s linear infinite;
}

@keyframes spin {
    to {
        transform: rotate(360deg);
    }
}

.officetime.primary-button {
    font-family: var(--officetime-font);
}
</style>
