<script setup lang="ts">
import { reactive, watch, onMounted } from 'vue';
import type { Palette } from '../types/organization';
import type { InputsData } from '../types/inputsData'

const props = defineProps<{
    modelValue: InputsData,
    colors: Palette,
    type: string,
    variant: string,
}>()

const emit = defineEmits<{
    (event: 'update:modelValue', value: InputsData): void
}>()

const state = reactive({
    input: props.modelValue || { value: '', isValid: true },
    isFocus: false,
});

const theme = reactive({
    font: '',
    bg: '',
    additionalColor: ''
})

onMounted(() => {
    if (props.variant === 'officetime') {
        const rootStyles = getComputedStyle(document.documentElement);

        theme.bg = rootStyles.getPropertyValue('--officetime-content-color');
        theme.additionalColor = rootStyles.getPropertyValue('--officetime-activity-color');
    }
})

function validatePhoneNumber(value: string) {
    if (!value) {
        state.input.isValid = true
        emit('update:modelValue', state.input)
    } else {
        const regex = /^[2579]\d{2}( \d{3}){2}$/
        const validValue = regex.test(value)

        state.input.isValid = validValue
        emit('update:modelValue', state.input)
    }
}

function validateEmail(value: string) {
    if (!value) {
        state.input.isValid = true
        emit('update:modelValue', state.input)
    } else {
        const emailRegex = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
        const validValue = emailRegex.test(value);

        state.input.isValid = validValue
        emit('update:modelValue', state.input)
    }
}

function inputFormater(event: Event) {
    const input = event.target as HTMLInputElement

    if (props.type === 'phone') {
        let value = input.value.replace(/\D/g, '')
        value = value.replace(/(.{3})/g, '$1 ').trim()

        input.value = value
        state.input.value = value
    }

    if (props.type === 'email') {
        state.input.value = input.value
    }

    emit('update:modelValue', state.input)
}

function clearInfo() {
    state.input.value = ''
    emit('update:modelValue', state.input)
}

watch(() => state.input.value, (newValue) => {
    if (props.type === 'phone') {
        validatePhoneNumber(newValue.toString())
    }
    if (props.type === 'email') {
        validateEmail(newValue.toString())
    }
});
</script>

<template>
    <div class="input-content">
        <div :class="[{ 'input-warn': !state.input.isValid }, `${props.variant}`, 'input-phone']"
            :style="{ outlineColor: state.isFocus && state.input.isValid ? (theme.additionalColor || props.colors.additionalColor) : 'transparent', backgroundColor: state.isFocus && state.input.isValid ? (props.variant === 'officetime' ? theme.bg : 'white') : '' }">
            <span v-if="props.type === 'phone'" class="country">
                <img src="../assets/icons/kg.svg" alt="">
                <p>996</p>
            </span>
            <input v-if="props.type === 'phone'"
                :style="{ backgroundColor: `${theme.bg}` }"
                :class="[`${props.variant}`, 'input']" type="tel" placeholder="000 000 000" :value="state.input.value"
                @focus="state.isFocus = true" @blur="state.isFocus = false" @input="inputFormater" :maxlength="11">
            <input v-if="props.type === 'email'" :class="[`${props.variant}`, 'input']"
                :style="{ backgroundColor: `${theme.bg}` }" type="email"
                placeholder="Введите свой email" :value="state.input.value" @focus="state.isFocus = true"
                @blur="state.isFocus = false" @input="inputFormater" :maxlength="255">
            <u-button :type="['remove']" @removeValue="clearInfo" class="clear-button"
                v-if="state.input.value.toString().length > 0" />
        </div>
        <transition name="slide-fade">
            <p class="warning-info" v-if="!state.input.isValid">
                <span>Формат номера неверный или номер введен не полностью.</span>
            </p>
        </transition>
    </div>
</template>

<style scoped>
.input-content {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: clamp(0.625em, 0.625em, 2.667vw);
}

.input-phone {
    position: relative;
    width: 100%;
    height: clamp(3.438em, 3.438em, 16vw);
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    color: var(--c-black);
    background-color: var(--grey);
    resize: none;
    cursor: text;
    overflow-wrap: normal;
    border: none;
    border-radius: clamp(1.438em, 1.438em, 6.667vw);
    padding: 0.813em 1em;
    gap: clamp(0.75em, 0.75em, 3.2vw);
    transition: 0.2s ease-in;
    outline: 0.3em solid;
}

.officetime.input-phone {
    background-color: var(--officetime-content-color);
}

.country {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: clamp(0.438em, 0.438em, 1.867vw);
}

.country img {
    width: clamp(1.438em, 1.438em, 6.667vw);
    height: auto;
}

.country p {
    font-family: 'Commissioner', sans-serif;
    font-weight: var(--regular);
    font-size: clamp(0.938em, 0.938em, 4vw);
    color: var(--grey-additional);
}

.officetime.country p {
    font-family: var(--officetime-font), sans-serif;
}

.input {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    background-color: transparent;
    resize: none;
    cursor: text;
    overflow-wrap: normal;
    border: none;
    font-family: 'Commissioner', sans-serif;
    font-weight: var(--regular);
    font-size: clamp(0.938em, 0.938em, 4vw);
    padding: 0;
    margin: 0;
}

.officetime.input {
    font-family: var(--officetime-font);
}

.input:focus {
    outline: none;
}

.input-phone:focus {
    transition: 0.2s ease-in;
}

.input::placeholder {
    color: var(--grey-additional);
}

.officetime.input {
    color: white;
}

.officetime.input::placeholder {
    color: rgba(255, 255, 255, 0.7);
}

.clear-button {
    position: absolute;
    z-index: 3000;
    top: 0;
    right: 0;
    margin: clamp(1.125em, 1.125em, 5.333vw);
    background: transparent;
    border: none;
    cursor: pointer;
}

.input-warn {
    outline-color: #FB000040 !important;
}

.officetime.input-warn {
    outline-color: #fb8282 !important;
}

.officetime.input-warn input {
    outline-color: #ff4343 !important;
}

.input-warn input {
    color: #FB0000 !important;
}

.officetime.input-warn p {
    color: #ff4343 !important;
}

.input-warn p {
    color: #FB0000 !important;
}

.warning-info {
    width: 100%;
    color: #FB0000 !important;
    font-weight: var(--medium);
    font-size: clamp(0.75em, 0.75em, 3.2vw);
}

.warning-info span {
    padding-left: clamp(1.125em, 1.125em, 4.8vw);
}

/* Animation */

.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(0.5, 0.5, 0.5, 0.5);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style>

<!-- <script setup lang="ts">
import { reactive, watch } from 'vue';
import type { Palette } from '../types/organization';

const props = defineProps<{
    modelValue: string,
    colors: Palette
}>();

const emit = defineEmits<{
    (event: 'isValid', value: boolean): void,
    (event: 'update:modelValue', value: string): void
}>()

const state = reactive({
    phoneNumber: props.modelValue || '',
    isFocus: false,
    isValid: true
});

function validatePhoneNumber(value: string) {
    if (!value) {
        return true;
    }

    const regex = /^[2579]\d{2}( \d{3}){2}$/;
    const validValue = regex.test(value)

    emit('isValid', validValue)

    return validValue;
}

function inputFormater(event: Event) {
    const input = event.target as HTMLInputElement;

    let value = input.value.replace(/\D/g, '')

    value = value.replace(/(.{3})/g, '$1 ').trim()

    input.value = value
    state.phoneNumber = value

    emit('update:modelValue', value)
}

function clearInfo() {
    state.phoneNumber = ''
    emit('update:modelValue', '')
}

watch(() => state.phoneNumber, (newValue) => {
    state.isValid = validatePhoneNumber(newValue)
})
</script>

<template>
    <div class="input-content">
        <div :class="[{ 'input-warn': !state.isValid }, 'input-phone']"
            :style="{ outlineColor: state.isFocus && state.isValid ? props.colors.additionalColor : 'transparent', backgroundColor: state.isFocus && state.isValid ? 'white' : '' }">
            <span class="country">
                <img src="../assets/icons/kg.svg" alt="">
                <p>996</p>
            </span>
            <input class="input" type="tel" placeholder="000 000 000" :value="state.phoneNumber"
                @focus="state.isFocus = true" @blur="state.isFocus = false" @input="inputFormater" :maxlength="11">
            <u-button :type="['remove']" @removeValue="clearInfo" class="clear-button"
                v-if="state.phoneNumber.length > 0" />
        </div>
        <transition name="slide-fade">
            <p class="warning-info" v-if="!state.isValid"><span>Формат номера неверный или номер введен
                    не полностью.</span></p>
        </transition>
    </div>
</template>

<style scoped>
.input-content {
    width: 100%;
    display: flex;
    flex-flow: column nowrap;
    gap: clamp(0.625em, 0.625em, 2.667vw);
}

.input-phone {
    position: relative;
    width: 100%;
    height: clamp(3.438em, 3.438em, 16vw);
    display: flex;
    align-items: center;
    flex-flow: row nowrap;
    color: var(--c-black);
    background-color: var(--grey);
    resize: none;
    cursor: text;
    overflow-wrap: normal;
    border: none;
    border-radius: clamp(1.438em, 1.438em, 6.667vw);
    padding: 0.813em 1em;
    gap: clamp(0.75em, 0.75em, 3.2vw);
    transition: 0.2s ease-in;
    outline: 0.3em solid;
}

.country {
    display: flex;
    flex-flow: row nowrap;
    align-items: center;
    gap: clamp(0.438em, 0.438em, 1.867vw);
}

.country img {
    width: clamp(1.438em, 1.438em, 6.667vw);
    height: auto;
}

.country p {
    font-family: 'Commissioner', sans-serif;
    font-weight: var(--regular);
    font-size: clamp(0.938em, 0.938em, 4vw);
    color: var(--grey-additional);
}

.input {
    width: 100%;
    height: auto;
    display: flex;
    flex-flow: row nowrap;
    background-color: transparent;
    resize: none;
    cursor: text;
    overflow-wrap: normal;
    border: none;
    font-family: 'Commissioner', sans-serif;
    font-weight: var(--regular);
    font-size: clamp(0.938em, 0.938em, 4vw);
    padding: 0;
    margin: 0;
}

.input:focus {
    outline: none;
}

.input-phone::focus {
    transition: 0.2s ease-in;
}

.input::placeholder {
    color: var(--grey-additional);
}

.clear-button {
    position: absolute;
    z-index: 3000;
    top: 0;
    right: 0;
    margin: clamp(1.125em, 1.125em, 5.333vw);
    background: transparent;
    border: none;
    cursor: pointer;
}

.input-warn {
    outline-color: #FB000040 !important;
}

.input-warn input {
    color: #FB0000 !important;
}

.input-warn p {
    color: #FB0000 !important;
}

.warning-info {
    width: 100%;
    color: #FB0000 !important;
    font-weight: var(--medium);
    font-size: clamp(0.75em, 0.75em, 3.2vw);
}

.warning-info span {
    padding-left: clamp(1.125em, 1.125em, 4.8vw);
}

/* Animation */

.slide-fade-enter-active {
    transition: all 0.5s ease-out;
}

.slide-fade-leave-active {
    transition: all 0.5s cubic-bezier(0.5, 0.5, 0.5, 0.5);
}

.slide-fade-enter-from,
.slide-fade-leave-to {
    transform: translateY(-20px);
    opacity: 0;
}
</style> -->